/**
 * Created by Lj on 2018/5/2.
 */

$color-grey: #f8f8f8;
html {
    -webkit-text-size-adjust: none;
    font-size: 625%;
    background: #ffffff;
}
body {
    font-size: 16px;
    max-width: 1600px;
    width: 100%;
    margin: 0 auto !important;
}
body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, a, big, em, font, strong, tt, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, caption, tbody, tfoot, thead, tr, th, tdinput {
    padding: 0;
    margin: 0;
    -webkit-tap-highlight-color:transparent;
}

img {
    border: 0px;
    width: auto;
    /*
    max-width: 100%;
    */
}

ul {
    list-style: none;
}

li {
    list-style: none;
    color: #454545;
    font-family: "NotoSansHans", "Lucida Console", Monaco, monospace;
}

hr {
    color: #FFFFFF;
}

body {
    font-family: "";
    color: #454545;
    width: 100%;
    height: 100%;
    text-align: center;
    cursor: default;
}

a, a:active, a:hover, a:link, a:visited {
    text-decoration: none;
    color: inherit;
    background: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent
}

li, li:active, li:hover, li:link, li:visited {
    text-decoration: none;
    color: inherit;
    background: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent
}

.hidden {
    display: none !important;
}

.bgGrey {
    background: $color-grey;
}

.colorBlue {
    color: #42ABE1;
}

.clearB {
    clear: both;
}

.flexViewB {
    display: -webkit-flex; /* Safari */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.flexViewC {
    display: -webkit-flex; /* Safari */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.flexViewSB {
    display: -webkit-flex; /* Safari */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
}

.flex1 {
    flex-grow: 1;
}
.relativeP{
    position: relative;
}